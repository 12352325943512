// OrderMedicines.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Update the import
import '../styles/Medicine.css'; // Ensure the path is correct
import '@fortawesome/fontawesome-free/css/all.min.css';


const Medicine = () => {
  const navigate = useNavigate(); // Use useNavigate instead

  const handleUploadClick = () => {
    navigate('/upload-prescription'); // Use navigate function
  };

  const handleCallClick = () => {
    window.location.href = 'tel:+918069809760';
  };

  return (
    <div className="container">
      <div className="left-column">
        <h1><i className="fas fa-pills"></i> Order Medicines Online</h1>
        <div className="offers">
        <p><i className="fas fa-percent"></i> Up to 25% Off</p>
          <p><i className="fas fa-box-open"></i> 1 Lakh+ Products</p>
          <p><i className="fas fa-undo-alt"></i> Easy Returns</p>
        </div>
        <input type="text" placeholder="Search for Medicines/Healthcare Products" />
      </div>
      <div className="right-column">
        <h2>Order via Prescription</h2>
        <div className="cards-container">
          <div className="card" onClick={handleUploadClick}>
            <h3> <i className="fas fa-file-upload"></i> Upload Prescription</h3>
            <p>Click here to upload your prescription.</p>
          </div>
          <div className="card" onClick={handleCallClick}>
            <h3> <i className="fas fa-phone"></i> Don't have a prescription</h3>
              <h2> Call +91 1234567890</h2>
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default Medicine;
