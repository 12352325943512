// src/components/PrescriptionUpload.js
import React, { useState } from 'react';
import '../styles/PrescriptionUpload.css'

const PrescriptionUpload = () => {
  const [prescription, setPrescription] = useState(null);
  const [address, setAddress] = useState('');
  const [orderStatus, setOrderStatus] = useState('');

  const handleFileChange = (e) => {
    setPrescription(e.target.files[0]);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!prescription || !address) {
      alert('Please upload a prescription and provide the address.');
      return;
    }

    const formData = new FormData();
    formData.append('prescription', prescription);
    formData.append('address', address);

    try {
      const response = await fetch('/api/orders', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        setOrderStatus('Order placed successfully! We will call you to confirm.');
      } else {
        setOrderStatus(`Error: ${result.error}`);
      }
    } catch (error) {
      setOrderStatus('Something went wrong. Please try again.');
    }
  };

  return (
    <div className="upload-container">
      <h2>Upload Your Prescription</h2>
      <div>
        
      </div>
      <form onSubmit={handleSubmit}>
        <label>Upload Prescription:</label>
        <input type="file" accept="image/*" onChange={handleFileChange} required />

        <label>Delivery Address:</label>
        <textarea value={address} onChange={handleAddressChange} required />

        <button type="submit">Place Order</button>
      </form>

      {orderStatus && <p>{orderStatus}</p>}
    </div>
  );
};

export default PrescriptionUpload;
