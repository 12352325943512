// src/pages/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase'; // Ensure this path is correct

const ProtectedRoute = ({ children }) => {
    const user = auth.currentUser; // Check if the user is authenticated

    return user ? children : <Navigate to="/signin" />;
};

export default ProtectedRoute;
