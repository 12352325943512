// src/components/MegaHeader.js
import React from 'react';
import '../styles/MegaHeader.css'; // Optional: Styling for the mega header
import { productsData } from '../data'; // Adjust the path based on your project structure

const BeautyHeader = () => {
  // Extract unique categories and their subcategories along with types
  const categories = productsData.beauty.reduce((acc, product) => {
    const { category, subCategory, type } = product; // Destructure type here
    if (!acc[category]) acc[category] = new Set();
    acc[category].add(`${subCategory} (${type})`); // Include type in subcategory
    return acc;
  }, {});

  return (
    <div className="mega-header">
      {Object.keys(categories).map((category) => (
        <div key={category} className="mega-header-section">
          <h3 className="category-title">{category}</h3>
          <ul className="subcategory-list">
            {[...categories[category]].map((subCategory) => (
              <li key={subCategory} className="subcategory-item">
                <a href={`#${subCategory.split(' (')[0]}`}>{subCategory}</a>
                {/* Display type in brackets next to the subcategory */}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default BeautyHeader;
