import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // For accessing product ID from URL and navigation
import { useCart } from '../context/CartContext';
import { productsData } from '../data'; 
import '../styles/ProductView.css'; // Import the CSS file

const ProductView = () => {
    const { id } = useParams(); // Get product ID from URL
    const { addToCart } = useCart(); // Use Cart Context to add items to the cart
    const [quantity, setQuantity] = useState(1); // Quantity state
    const navigate = useNavigate(); // Use navigate for redirection

    // Fetch the product based on the ID
    const product = productsData.medicine.find((item) => item.id === parseInt(id));

    // Check if the product exists
    if (!product) {
        return <div className="error-message">Product not found</div>; // Handle product not found case
    }

    // Handle adding to cart
    const handleAddToCart = () => {
        addToCart({ ...product, quantity });
    };

    // Handle quantity change
    const handleQuantityChange = (e) => {
        setQuantity(Math.max(1, parseInt(e.target.value))); // Ensure quantity is at least 1
    };

    // Handle Buy Now click
    const handleBuyNow = () => {
        addToCart({ ...product, quantity }); // Add product to cart first
        navigate('/checkout'); // Navigate to the checkout page
    };

    return (
        <div className="product-view-container">
            <div className="product-image">
                <img  src={product.image}  alt={product.name} />
            </div>
            <div className="product-details">
                <h1>{product.name}</h1>
                <p className="price">Price: ₹{product.price.toFixed(2)}</p>
                <p>Description: {product.description}</p>
                <p>Unit: {product.unit}</p>
                <p>Category: {product.category}</p>
                <p>Sub-Category: {product.subCategory}</p>

                <div className="product-quantity">
                    <label htmlFor="quantity">Quantity: </label>
                    <input
                        type="number"
                        id="quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                        min="1" // Minimum quantity
                    />
                </div>

                <div className="action-buttons">
                    <button className="add-to-cart-btn" onClick={handleAddToCart}>
                        Add to Cart
                    </button>

                    <button className="buy-now-btn" onClick={handleBuyNow}>
                        Buy Now
                    </button>
                </div>
            </div>
            <div className="payment-details">
                <h2>Payment Details</h2>
                <p>Total Amount: ₹{(product.price * quantity).toFixed(2)}</p>
                <p>Delivery Charges: ₹49.00</p>
                <p>Total Payable: ₹{((product.price * quantity) + 49).toFixed(2)}</p>
                <h3>Select Payment Method</h3>
                <select>
                    <option value="Credit Card">Credit Card</option>
                    <option value="PayPal">PayPal</option>
                    <option value="Debit Card">Debit Card</option>
                </select>
            </div>
        </div>
    );
};

export default ProductView;
