// src/pages/Checkout.js

import React, { useState } from 'react';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import '../styles/Checkout.css'; // Assuming your CSS is in a separate file

const Checkout = () => {
  const { cartItems, clearCart } = useCart();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('Credit Card');
  const navigate = useNavigate();

  const totalAmount = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const totalDiscount = 33.66; // This can be dynamic based on the promo code
  const deliveryCharges = 49;
  const totalPayable = totalAmount - totalDiscount + deliveryCharges;

  const handleCheckout = (e) => {
    e.preventDefault();
    // Here, you can add your payment processing logic

    // Clear the cart after successful payment
    clearCart();
    alert('Payment successful! Thank you for your purchase.');
    navigate('/'); // Redirect to home or any other page
  };

  if (cartItems.length === 0) {
    return <h2>Your cart is empty. Please add items to the cart.</h2>;
  }

  return (
    <div className="checkout-container">
      <h2>Checkout</h2>
      <form onSubmit={handleCheckout}>
        <div>
          <label>
            Name:
            <input 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              required 
            />
          </label>
        </div>
        <div>
          <label>
            Address:
            <input 
              type="text" 
              value={address} 
              onChange={(e) => setAddress(e.target.value)} 
              required 
            />
          </label>
        </div>
        <div>
          <label>
            Payment Method:
            <select 
              value={paymentMethod} 
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="Credit Card">Credit Card</option>
              <option value="PayPal">PayPal</option>
              <option value="Debit Card">Debit Card</option>
            </select>
          </label>
        </div>
        <h3>Items in Cart:</h3>
        <ul className="cart-items-summary">
          {cartItems.map(item => (
            <li key={item.id}>
              {item.name} - ₹{item.price.toFixed(2)} x {item.quantity}
            </li>
          ))}
        </ul>
        <p>Total Amount: ₹{totalAmount.toFixed(2)}</p>
        <p>Discount: - ₹{totalDiscount.toFixed(2)}</p>
        <p>Delivery Charges: ₹{deliveryCharges}</p>
        <h4>Total Payable: ₹{totalPayable.toFixed(2)}</h4>
        <button type="submit" className="complete-btn">Pay Now</button>
      </form>
    </div>
  );
};

export default Checkout;
