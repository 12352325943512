// UploadPrescription.js
import React, { useState } from 'react';
import '../styles/UploadPrescription.css'; // Make sure the path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faFileUpload } from '@fortawesome/free-solid-svg-icons';

const UploadPrescription = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      alert(`Uploading ${file.name}`);
      // Implement actual upload functionality here
    } else {
      alert('Please select a file to upload.');
    }
  };

  return (
    <div className="upload-container">
      <h1><FontAwesomeIcon icon={faFileUpload} /> Upload Prescription</h1>
      <div className="file-upload">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          id="file-input"
        />
        <label htmlFor="file-input" className="file-label">
          <FontAwesomeIcon icon={faUpload} /> Choose File
        </label>
      </div>
      <button onClick={handleUpload} className="upload-button">Upload</button>
    </div>
  );
};

export default UploadPrescription;
