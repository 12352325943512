import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ManageProducts.css';

const ManageProducts = ({ products, deleteProduct }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const navigate = useNavigate();

  const handleCheckboxChange = (id) => {
    setSelectedProducts((prev) =>
      prev.includes(id) ? prev.filter((productId) => productId !== id) : [...prev, id]
    );
  };

  const handleEdit = (productId) => {
    navigate(`/edit-product/${productId}`); // Navigate to edit page with product ID
  };

  const handleDeleteSelected = () => {
    selectedProducts.forEach((id) => deleteProduct(id));
    setSelectedProducts([]);
  };

  return (
    <div className="manage-products-container">
      <h2>Manage Products</h2>
      <button className="btn-add" onClick={() => navigate('/add-product')}>
        ➕ Add Product
      </button>
      <button
        className="btn-delete"
        onClick={handleDeleteSelected}
        disabled={selectedProducts.length === 0}
      >
        &#x1F5D1; Delete Selected
      </button>

      <table className="product-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>ID</th>
            <th>Name</th>
            <th>Company</th>
            <th>Price (₹)</th>
            <th>Stock</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedProducts.includes(product.id)}
                  onChange={() => handleCheckboxChange(product.id)}
                />
              </td>
              <td>{product.id}</td>
              <td>
                <div className="name-container">{product.name}</div>
              </td>
              <td>
                <div className="company-container">{product.company}</div>
              </td>
              <td>{product.price}</td>
              <td>{product.stock}</td>
              <td>
                <img
                  src={product.image}
                  alt={product.name}
                  style={{ width: '50px' }}
                />
              </td>
              <td className="actionbuttons">
                <button
                  className="btn-edit"
                  onClick={() => handleEdit(product.id)}
                >
                  ✏️
                </button>
                <button
                  className="btn-delete"
                  onClick={() => deleteProduct(product.id)}
                >
                  &#x1F5D1;
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageProducts;
