import React, { useState } from 'react';
import { auth } from '../firebase'; // Ensure this imports the initialized auth instance
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const SignUp = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const setUpRecaptcha = () => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          console.log("reCAPTCHA solved!"); // Log success
        },
        'expired-callback': () => {
          console.log("reCAPTCHA expired. Please solve it again.");
        }
      }, auth);
    } catch (error) {
      console.error("Error setting up reCAPTCHA:", error);
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    setUpRecaptcha(); // Setup reCAPTCHA

    const appVerifier = window.recaptchaVerifier;

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      setVerificationId(confirmationResult.verificationId);
      setSuccessMessage('OTP sent successfully! Please check your SMS.');
      console.log("OTP sent successfully!"); // Log success
    } catch (error) {
      setErrorMessage(error.message);
      console.error('Error during OTP send:', error); // Log the error
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const confirmationResult = await auth.signInWithPhoneNumber(otp, verificationId);
      setSuccessMessage('Phone number verified successfully!');
      console.log("Phone number verified successfully!"); // Log success
    } catch (error) {
      setErrorMessage('Invalid OTP. Please try again.');
      console.error('Error during OTP verification:', error); // Log the error
    }
  };

  return (
    <div>
      <h2>Phone Number Authentication</h2>
      <div id="recaptcha-container"></div>
      {verificationId === null ? (
        <form onSubmit={handleSendOtp}>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your phone number"
            required
          />
          <button type="submit">Send OTP</button>
        </form>
      ) : (
        <form onSubmit={handleVerifyOtp}>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter the OTP"
            required
          />
          <button type="submit">Verify OTP</button>
        </form>
      )}

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
    </div>
  );
};

export default SignUp;
