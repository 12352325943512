import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ProductEntry.css';

const ProductEntry = ({ addProduct }) => {
  const [product, setProduct] = useState({
    name: '',
    company: '',
    price: '',
    stock: '',
    category: '',
    subcategory: '',
    batchNumber: '',
    expiryDate: '',
    description: '',
    dosage:'',
    image: null, // File upload
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    // Handle image file upload
    setProduct((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addProduct(product); // Add product logic
    navigate('/manage-products');
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Add Pharmacy Product</h2>
      <form onSubmit={handleSubmit}>
        {/* Row 1: Name and Category */}
        <div className="row">
          <div className="input-group">
            <label>Product Name</label>
            <input
              type="text"
              name="name"
              value={product.name}
              onChange={handleChange}
              required
            />
          </div>
        
        </div>

        {/* Row 2: Sub-category and Batch Number */}
        <div className="row">
        <div className="input-group">
            <label>Category</label>
            <select
              name="category"
              value={product.category}
              onChange={handleChange}
              required
            >
              <option value="">Select Category</option>
              <option value="Medicine">Medicine</option>
              <option value="OTC">OTC</option>
              <option value="Supplements">Supplements</option>
            </select>
          </div>
          <div className="input-group">
            <label>Sub-Category</label>
            <select
              name="subcategory"
              value={product.subcategory}
              onChange={handleChange}
              required
            >
              <option value="">Select Sub-Category</option>
              <option value="Antibiotic">Antibiotic</option>
              <option value="Pain Relief">Pain Relief</option>
              <option value="Vitamins">Vitamins</option>
            </select>
          </div>
        
        </div>

        {/* Row 3: Expiry Date and Price */}
        <div className="row">
        <div className="input-group">
            <label>Batch Number</label>
            <input
              type="text"
              name="batchNumber"
              value={product.batchNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label>Expiry Date</label>
            <input
              type="date"
              name="expiryDate"
              value={product.expiryDate}
              onChange={handleChange}
              required
            />
          </div>
         
        </div>

        {/* Row 4: Stock and Company */}
        <div className="row">
        <div className="input-group">
            <label>Price (₹)</label>
            <input
              type="number"
              name="price"
              value={product.price}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label>Stock Quantity</label>
            <input
              type="number"
              name="stock"
              value={product.stock}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
          <label>Dosage</label>
          <input
            type="text"
            name="dosage"
            value={product.dosage}
            onChange={handleChange}
            required
          />
        </div>
       
        </div>

        {/* Row 5: Description */}
        <div className="row">
        <div className="input-group">
            <label>Company</label>
            <input
              type="text"
              name="company"
              value={product.company}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label>Description</label>
            <textarea
              name="description"
              value={product.description}
              onChange={handleChange}
              rows="3"
              required
            ></textarea>
          </div>
        </div>

        {/* Row 6: Image Upload */}
        <div className="row">
          <div className="input-group">
            <label>Product Image</label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <button type="submit" className="btn submit-btn">
          Add Product
        </button>
      </form>
    </div>
  );
};

export default ProductEntry;
