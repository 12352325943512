// Footer.js
import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="roof"></div> {/* Decorative Roof */}
      <div className="footer-buttom ">
      <div className="footer-container">
        {/* Company Info Section */}
        <div className="footer-column">
          <h3>GharMeds</h3>
          <p>Your trusted online pharmacy. Get medicines delivered to your doorstep with ease and reliability.</p>
        </div>

        {/* Quick Links Section */}
        <div className="footer-column">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/products">Products</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="footer-column">
          <h4>Contact Us</h4>
          <p>Email: support@gharmeds.com</p>
          <p>Phone: +91 98765 43210</p>
          <p>Address: Kurnool, Andhra Pradesh, India</p>
        </div>

        {/* Newsletter Signup */}
        <div className="footer-column">
          <h4>Stay Updated</h4>
          <input type="email" placeholder="Enter your email" />
          <button>Subscribe</button>
        </div>
      </div> {/* Closing div for footer-container */}

      {/* Social Media Icons */}
      <div className="footer-social">
        <a href="#"><FaFacebookF /></a>
        <a href="#"><FaInstagram /></a>
        <a href="#"><FaTwitter /></a>
      </div> {/* Closing div for social media icons */}

      <div className="footer-bottom">
        <p>&copy; 2024 GharMeds. All Rights Reserved.</p>
      </div>
      </div>
    
    </footer>
  );
};

export default Footer;
