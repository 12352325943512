// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import adminUser from '../admin'; // Admin user data
import '../styles/Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // State for error message
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Fetch users from localStorage and handle potential parsing errors
    let users = [];
    try {
      users = JSON.parse(localStorage.getItem('users')) || [];
    } catch (err) {
      console.error('Failed to parse users:', err);
    }

    const regularUser = users.find(
      (user) => user.username === username && user.password === password
    );

    if (username === adminUser.username && password === adminUser.password) {
      localStorage.setItem('currentUser', JSON.stringify(adminUser));
      navigate('/manage-products'); // Redirect admin to manage products page
    } else if (regularUser) {
      localStorage.setItem('currentUser', JSON.stringify(regularUser));
      navigate('/'); // Redirect regular user to home page
    } else {
      setError('Invalid username or password.');
    }
  };

  return (
    <div className="login-page">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Login</button>
      </form>

      {error && <p className="error-message">{error}</p>} {/* Display error */}
      
      <p>
        Don't have an account? <Link to="/signup">Sign Up</Link>
      </p>
    </div>
  );
};

export default Login;
