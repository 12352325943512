import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcaseMedical, faHeart, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../context/CartContext';
import WellnessHeader from '../components/WellnessHeader'; 
import BeautyHeader from '../components/BeautyHeader'; 
import HealthCornerHeader from '../components/HealthCornerHeader'; 
import '../styles/Header.css';

const Header = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isWellnessMenuOpen, setIsWellnessMenuOpen] = useState(false);
  const [isBeautyMenuOpen, setIsBeautyMenuOpen] = useState(false);
  const [isHealthCornerMenuOpen, setIsHealthCornerMenuOpen] = useState(false);
  const { cartItemCount } = useCart();
  
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  // Load current user from local storage on component mount
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log('Searching for:', searchQuery);
  };

  const openWellnessMenu = () => setIsWellnessMenuOpen(true);
  const closeWellnessMenu = () => setIsWellnessMenuOpen(false);

  const openBeautyMenu = () => setIsBeautyMenuOpen(true);
  const closeBeautyMenu = () => setIsBeautyMenuOpen(false);

  const openHealthCornerMenu = () => setIsHealthCornerMenuOpen(true);
  const closeHealthCornerMenu = () => setIsHealthCornerMenuOpen(false);

  const handleLogout = () => {
    localStorage.removeItem('currentUser');
    setCurrentUser(null);
    navigate('/'); // Redirect to home or login page after logout
  };

  return (
    <header className="header">
      <div className="top-section">
        <div className="logo">
          <Link to="/">
            <img src="/images/main_logo.png" alt="Logo" className="logo-img" />
          </Link>
        </div>

        <form className="search-form" onSubmit={handleSearchSubmit}>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search for medicine & wellness products…"
            className="search-input"
          />
          <button type="submit" className="search-btn">Search</button>
        </form>

        <div className="header-right">
          <Link to="/cart" className="cart-link">
            <span className="cart-icon">🛒</span>
            <strong>{cartItemCount()}</strong>
          </Link>
          
          {currentUser ? (
            <div className="user-info">
              <span>
                Welcome, {currentUser.details?.name || 'User'}!
              </span>
              <button onClick={handleLogout} className="logout-btn">Logout</button>
            </div>
          ) : (
            <Link to="/login" className="login-btn">
              Sign In / Sign Up
            </Link>
          )}
        </div>
      </div>

      <nav className="nav-links">
        <Link to="/medicine" className="nav-link">
          <FontAwesomeIcon icon={faBriefcaseMedical} size="lg" />
          Medicine
        </Link>

        <div 
          className="nav-link-container" 
          onMouseEnter={openWellnessMenu} 
          onMouseLeave={closeWellnessMenu}
        >
          <Link to="/wellness" className="nav-link">
            <FontAwesomeIcon icon={faHeart} size="lg" />
            Wellness
          </Link>
          {isWellnessMenuOpen && <WellnessHeader />}
        </div>

        <div 
          className="nav-link-container" 
          onMouseEnter={openBeautyMenu} 
          onMouseLeave={closeBeautyMenu}
        >
          <Link to="/beauty" className="nav-link">
            <FontAwesomeIcon icon={faStar} size="lg" />
            Beauty
          </Link>
          {isBeautyMenuOpen && <BeautyHeader />}
        </div>

        <div 
          className="nav-link-container" 
          onMouseEnter={openHealthCornerMenu} 
          onMouseLeave={closeHealthCornerMenu}
        >
          <Link to="/health-corner" className="nav-link">
            <FontAwesomeIcon icon={faUser} size="lg" />
            Health Corner
          </Link>
          {isHealthCornerMenuOpen && <HealthCornerHeader />}
        </div>
      </nav>
    </header>
  );
};

export default Header;
