// src/pages/Cart.js

import React from 'react';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import '../styles/Cart.css'; // Make sure to create a CSS file for styling

const Cart = () => {
  const { cartItems, clearCart, removeFromCart } = useCart(); // Keep removeFromCart here

  const totalAmount = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const deliveryCharges = 49; // example delivery charge
  const totalPayable = totalAmount + deliveryCharges;
  const navigate = useNavigate();

  return (
    <div className="cart-container">
      <h2>Your Cart</h2>

      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <>
          <div className="prescription-items">
            <h3>PRESCRIPTION ITEMS</h3>
            {cartItems.map(item => (
              <div key={item.id} className="cart-item">
                <div className="item-details">
                  <h4>{item.name}</h4>
                  <p className="stock-info">Only {item.stock} Left In Stock</p>
                  <p>Mfr: {item.manufacturer}</p>
                  <button onClick={() => removeFromCart(item.id)} className="remove-btn">Remove</button>
                </div>
                <div className="item-price">
                  <p className="price">₹ {item.price} <span className="discount">Save ₹ {item.discount}</span></p>
                  <p>Qty: {item.quantity}</p>
                  <p className="delivery-info">Delivery between OCTOBER 10 - OCTOBER 11</p>
                </div>
              </div>
            ))}
          </div>

          <div className="cart-summary">
            <h3>Cart Summary</h3>
            <p>MRP Total: ₹ {totalAmount.toFixed(2)}</p>
            <p>Additional Discount: - ₹ {totalAmount * 0.1} </p> {/* Example discount calculation */}
            <p>Total Amount: ₹ {(totalAmount - totalAmount * 0.1).toFixed(2)}</p>
            <p>Shipping/Delivery Charges: ₹ {deliveryCharges}</p>
            <h4>Total Payable: ₹ {totalPayable.toFixed(2)}</h4>
          </div>

          <div className="cart-promo">
            <h3>Promo Code</h3>
            <input type="text" placeholder="APPLY PROMOCODE" />
            <button className="apply-promo-btn">Apply Promo Code</button>
          </div>

          <button onClick={() => navigate('/checkout')} className="checkout-btn">Proceed to Checkout</button>
        </>
      )}
    </div>
  );
};

export default Cart;
