import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Cart from './pages/Cart';
import Categories from './pages/Categories';
import Medicine from './pages/Medicine';
import Wellness from './pages/Wellness';
import Beauty from './pages/Beauty';
import HealthCorner from './pages/HealthCorner';
import Checkout from './pages/Checkout';
import ProductView from './pages/ProductView';
import UploadPrescription from './pages/UploadPrescription';
import ProductEntry from './pages/ProductEntry';
import ProductManagement from './pages/ProductManagement';
import EditProduct from './pages/EditProduct';
import ProtectedRoute from './pages/ProtectedRoute'; // Import the ProtectedRoute
import SignUp from './pages/SignUp';
import { AuthProvider } from './AuthContext'; // Adjust the path as necessary
import OtpAuth from './utils/OtpAuth'; // Adjust import path

const App = () => {
  const [products, setProducts] = useState([
    { id: 1, name: 'Paracetamol', company: 'ABC Pharma', price: 10, stock: 100 },
    { id: 2, name: 'Ibuprofen', company: 'XYZ Pharma', price: 20, stock: 50 },
  ]);

  const addProduct = (newProduct) => {
    setProducts((prev) => [
      ...prev,
      { ...newProduct, id: prev.length + 1 },
    ]);
  };

  const updateProduct = (id, updatedProduct) => {
    setProducts((prev) =>
      prev.map((product) => (product.id === id ? { ...product, ...updatedProduct } : product))
    );
  };

  const deleteProduct = (id) => {
    setProducts((prev) => prev.filter((product) => product.id !== id));
  };

  return (
    <CartProvider>
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/medicine" element={<Medicine />} />
            <Route path="/wellness" element={<Wellness />} />
            <Route path="/beauty" element={<Beauty />} />
            <Route path="/health-corner" element={<HealthCorner />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/product/:id" element={<ProductView />} />
            <Route path="/upload-prescription" element={<UploadPrescription />} />
            <Route path="/signup" element={<SignUp />} />

            {/* Protected Routes for Admin */}
            <Route
              path="/manage-products"
              element={
                <ProtectedRoute 
                  element={<ProductManagement products={products} deleteProduct={deleteProduct} updateProduct={updateProduct} />} 
                />
              }
            />
            <Route
              path="/add-product"
              element={
                <ProtectedRoute 
                  element={<ProductEntry addProduct={addProduct} />} 
                />
              }
            />
            <Route
              path="/edit-product/:id"
              element={
                <ProtectedRoute 
                  element={<EditProduct products={products} updateProduct={updateProduct} />} 
                />
              }
            />

            {/* Fallback route */}
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
};

export default App;
