// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyChwEVjxsCEgwclhJAymPOj-UqYvFZMWGw",
    authDomain: "gharmedstrail.firebaseapp.com",
    projectId: "gharmedstrail",
    storageBucket: "gharmedstrail.appspot.com",
    messagingSenderId: "705847138211",
    appId: "1:705847138211:web:7ecc0d38f3dc20df5eac16"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
