// src/components/EditProduct.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/ProductEntry.css'; // Reuse the same CSS as ProductEntry

const EditProduct = ({ products, updateProduct }) => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const foundProduct = products.find((p) => p.id === parseInt(id));
    if (foundProduct) {
      setProduct(foundProduct);
    } else {
      navigate('/manage-products'); // Redirect if product not found
    }
  }, [id, products, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in product) {
      formData.append(key, product[key]);
    }
    updateProduct(product.id, formData);
    navigate('/manage-products');
  };

  if (!product) return null;

  return (
    <div className="form-container">
      <h2 className="form-title">Edit Pharmacy Product</h2>
      <form onSubmit={handleUpdate}>
      <div className="row">
          <div className="input-group">
            <label>Product Name</label>
            <input
              type="text"
              name="name"
              value={product.name}
              onChange={handleInputChange}
              required
            />
          </div>
        
        </div>

        {/* Row 2: Sub-category and Batch Number */}
        <div className="row">
        <div className="input-group">
            <label>Category</label>
            <select
              name="category"
              value={product.category}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Category</option>
              <option value="Medicine">Medicine</option>
              <option value="OTC">OTC</option>
              <option value="Supplements">Supplements</option>
            </select>
          </div>
          <div className="input-group">
            <label>Sub-Category</label>
            <select
              name="subcategory"
              value={product.subcategory}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Sub-Category</option>
              <option value="Antibiotic">Antibiotic</option>
              <option value="Pain Relief">Pain Relief</option>
              <option value="Vitamins">Vitamins</option>
            </select>
          </div>
        
        </div>

        {/* Row 3: Expiry Date and Price */}
        <div className="row">
        <div className="input-group">
            <label>Batch Number</label>
            <input
              type="text"
              name="batchNumber"
              value={product.batchNumber}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label>Expiry Date</label>
            <input
              type="date"
              name="expiryDate"
              value={product.expiryDate}
              onChange={handleInputChange}
              required
            />
          </div>
         
        </div>

        {/* Row 4: Stock and Company */}
        <div className="row">
        <div className="input-group">
            <label>Price (₹)</label>
            <input
              type="number"
              name="price"
              value={product.price}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label>Stock Quantity</label>
            <input
              type="number"
              name="stock"
              value={product.stock}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
          <label>Dosage</label>
          <input
            type="text"
            name="dosage"
            value={product.dosage}
            onChange={handleInputChange}
            required
          />
        </div>
       
        </div>

        {/* Row 5: Description */}
        <div className="row">
        <div className="input-group">
            <label>Company</label>
            <input
              type="text"
              name="company"
              value={product.company}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label>Description</label>
            <textarea
              name="description"
              value={product.description}
              onChange={handleInputChange}
              rows="3"
              required
            ></textarea>
          </div>
        </div>

        {/* Row 6: Image Upload */}
        <div className="row">
          <div className="input-group">
            <label>Product Image</label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <button type="submit" className="btn submit-btn">
          Update Product
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
