



import React from 'react';
import { useCart } from '../context/CartContext';
import { productsData } from '../data'; // Assuming productsData is exported from data.js
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/HealthCorner.css'; // Optional: Import a CSS file for styling

const  Beauty = () => {
    const { addToCart } = useCart();
    const navigate = useNavigate(); // Initialize useNavigate

    // Function to handle product click
    const handleProductClick = (id) => {
        navigate(`/product/${id}`); // Navigate to the product view page with the product ID
    };

    return (
        <div className="medicine-container">
            <h2>Beauty Products</h2>
            <div className="products-grid">
                {productsData.beauty.map(product => (
                    <div 
                        className="product-card" 
                        key={product.id} 
                        onClick={() => handleProductClick(product.id)} // Make the entire card clickable
                    >
                        <img src={product.image} alt={product.name} className="product-image" />
                        <h3 className="product-name">{product.name}</h3>
                        <p className="product-description">{product.description}</p>
                        <p className="product-price">₹{product.price}</p>
                        <button 
                            onClick={(e) => { 
                                e.stopPropagation(); // Prevent click from bubbling up to the card
                                addToCart({ ...product, quantity: 1 });
                            }} 
                            className="add-to-cart-button"
                        >
                            Add to Cart
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default  Beauty;
