export const productsData= {
    medicine: [
      {
        "id": 1,
        "name": "Paracetamol 500mg",
        "price": 30, // Store as a number
        "image": "/images/medicine/product_1.png",
        "description": "Used for pain relief and fever reduction.",
        "category": "Pain Reliever",
        "subCategory": "Analgesics",
        "unit": "500mg",
        "type": "Tablet",
        "company": "Pharma Co.",
        "thumbnails": [ // Ensure proper syntax with commas
          'path/to/thumb1.jpg',
          'path/to/thumb2.jpg',
          'path/to/thumb3.jpg',
      ],
      },
      
        { 
          "id": 2, 
          "name": "Aspirin 100mg", 
          "price": 40, 
          "image": "/images/medicine/product_2.png", 
          "description": "Effective for reducing inflammation and pain.",
          "category": "Pain Reliever",
          "subCategory": "Analgesics",
          "unit": "100mg",
          "type": "Capsule",
          "company": "HealthMed",
           "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 3, 
          "name": "Amoxicillin 500mg", 
          "price": 100, 
          "image": "/images/medicine/product_3.png", 
          "description": "Antibiotic used to treat various infections.",
          "category": "Antibiotic",
          "subCategory": "Infection Treatment",
          "unit": "500mg",
          "type": "Tablet",
          "company": "MediHealth",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 4, 
          "name": "Ibuprofen 400mg", 
          "price": 50, 
          "image": "/images/medicine/product_4.png", 
          "description": "Non-steroidal anti-inflammatory drug (NSAID).",
          "category": "Pain Reliever",
          "subCategory": "NSAIDs",
          "unit": "400mg",
          "type": "Tablet",
          "company": "Wellness Pharma",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 5, 
          "name": "Cetirizine 10mg", 
          "price": 60, 
          "image": "/images/medicine/product_5.png", 
          "description": "Antihistamine for allergy relief.",
          "category": "Antihistamine",
          "subCategory": "Allergy Relief",
          "unit": "10mg",
          "type": "Capsule",
          "company": "AllergyMed",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 6, 
          "name": "Omeprazole 20mg", 
          "price": 80, 
          "image": "/images/medicine/product_6.png", 
          "description": "Used to treat acid reflux and ulcers.",
          "category": "Gastrointestinal",
          "subCategory": "Proton Pump Inhibitors",
          "unit": "20mg",
          "type": "Tablet",
          "company": "GastroCare",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 7, 
          "name": "Loratadine 10mg", 
          "price": 80, 
          "image": "/images/medicine/product_7.png", 
          "description": "Non-drowsy antihistamine for allergies.",
          "category": "Antihistamine",
          "subCategory": "Allergy Relief",
          "type": "Injection",
          "unit": "10mg",
          "company": "LoraHealth",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 8, 
          "name": "Metformin 500mg", 
          "price": 120, 
          "image": "/images/medicine/product_1.png", 
          "description": "Medication for managing type 2 diabetes.",
          "category": "Diabetes",
          "subCategory": "Blood Sugar Control",
          "unit": "500mg",
          "type": "Tablet",
          "company": "DiabetesCo",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 9, 
          "name": "Simvastatin 10mg", 
          "price": 150, 
          "image": "/images/medicine/product_2.png", 
          "description": "Used to lower cholesterol levels.",
          "category": "Cholesterol",
          "subCategory": "Lipid-Lowering",
          "unit": "10mg",
          "type": "Softgel",
          "company": "HeartGuard",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 10, 
          "name": "Losartan 50mg", 
          "price": 90, 
          "image": "/images/medicine/product_3.png", 
          "description": "Medication for high blood pressure.",
          "category": "Hypertension",
          "subCategory": "Blood Pressure Control",
          "unit": "50mg",
          "type": "Syrup",
          "company": "BP Solutions",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 11, 
          "name": "Levothyroxine 50mcg", 
          "price": 140, 
          "image": "/images/medicine/product_4.png", 
          "description": "Thyroid hormone replacement therapy.",
          "category": "Thyroid",
          "subCategory": "Hormonal Therapy",
          "unit": "50mcg",
          "type": "Injection",
          "company": "ThyroidMed",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 12, 
          "name": "Sertraline 50mg", 
          "price": 200, 
          "image": "/images/medicine/product_5.png", 
          "description": "Antidepressant used to treat anxiety and depression.",
          "category": "Antidepressant",
          "subCategory": "Mental Health",
          "unit": "50mg",
          "type": "Tablet",
          "company": "MindCare",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 13, 
          "name": "Albuterol Inhaler", 
          "price": 300, 
          "image": "/images/medicine/product_6.png", 
          "description": "Used for asthma and COPD relief.",
          "category": "Respiratory",
          "subCategory": "Asthma Management",
          "unit": "1 Inhaler",
          "type": "Tablet",
          "company": "Respiratory Health",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 14, 
          "name": "Dextromethorphan Cough Syrup", 
          "price": 80, 
          "image": "/images/medicine/product_7.png", 
          "description": "Cough suppressant for dry cough.",
          "category": "Cough Medicine",
          "subCategory": "Cough Relief",
          "unit": "100ml",
          "type": "Drops",
          "company": "CoughEase",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 15, 
          "name": "Diphenhydramine 25mg", 
          "price": 50, 
          "image": "/images/medicine/product_1.png", 
          "description": "Antihistamine for allergy relief and sleep aid.",
          "category": "Antihistamine",
          "subCategory": "Sleep Aid",
          "unit": "25mg",
          "type": "Tablet",
          "company": "SleepWell",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 16, 
          "name": "Fexofenadine 120mg", 
          "price": 70, 
          "image": "/images/medicine/product_2.png", 
          "description": "Non-drowsy antihistamine for allergy symptoms.",
          "category": "Antihistamine",
          "subCategory": "Allergy Relief",
          "unit": "120mg",
          "type": "Tablet",
          "company": "AllergyCare",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 17, 
          "name": "Hydrochlorothiazide 25mg", 
          "price": 100, 
          "image": "/images/medicine/product_2.png", 
          "description": "Diuretic used to treat high blood pressure.",
          "category": "Diuretic",
          "subCategory": "Blood Pressure Control",
          "unit": "25mg",
          "type": "Tablet",
          "company": "Diuretic Solutions",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 18, 
          "name": "Tamsulosin 0.4mg", 
          "price": 18, 
          "image": "/images/medicine/product_3.png", 
          "description": "Used for benign prostatic hyperplasia (BPH).",
          "category": "Urology",
          "subCategory": "Prostate Health",
          "unit": "0.4mg",
          "type": "Tablet",
          "company": "UrologyMed",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 19, 
          "name": "Naproxen 250mg", 
          "price": 60, 
          "image": "/images/medicine/product_4.png", 
          "description": "NSAID for pain relief and inflammation.",
          "category": "Pain Reliever",
          "subCategory": "NSAIDs",
          "unit": "250mg",
          "type": "Tablet",
          "company": "PainRelief Co.",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        },
        { 
          "id": 20, 
          "name": "Montelukast 10mg", 
          "price": 75, 
          "image": "/images/medicine/product_6.png", 
          "description": "Used for asthma and allergic rhinitis.",
          "category": "Respiratory",
          "subCategory": "Asthma Management",
          "unit": "10mg",
          "type": "Tablet",
          "company": "Respiratory Care",
          "thumbnails": [ // Ensure proper syntax with commas
            'path/to/thumb1.jpg',
            'path/to/thumb2.jpg',
            'path/to/thumb3.jpg',
        ],
        }
      ],
  
      
            wellness: [
                {
                    "id": 1,
                    "name": "Hydrating Facial Serum",
                    "price": 900,
                    "image": "/images/beauty/product_1.png",
                    "description": "Deeply hydrates and plumps skin.",
                    "category": "Skincare",
                    "subCategory": "Serums",
                    "unit": "30ml",
                    "company": "Radiant Skin",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Serum"
                },
                {
                    "id": 2,
                    "name": "Nourishing Night Cream",
                    "price": 950,
                    "image": "/images/beauty/product_2.png",
                    "description": "Repairs and rejuvenates skin overnight.",
                    "category": "Skincare",
                    "subCategory": "Creams",
                    "unit": "50ml",
                    "company": "Glow Beauty",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Cream"
                },
                {
                    "id": 3,
                    "name": "Brightening Face Mask",
                    "price": 600,
                    "image": "/images/beauty/product_3.png",
                    "description": "Evens skin tone and brightens complexion.",
                    "category": "Skincare",
                    "subCategory": "Masks",
                    "unit": "100ml",
                    "company": "Bright Skin Co.",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Mask"
                },
                {
                    "id": 4,
                    "name": "Soothing Aloe Vera Gel",
                    "price": 400,
                    "image": "/images/beauty/product_4.png",
                    "description": "Calms and hydrates irritated skin.",
                    "category": "Skincare",
                    "subCategory": "Gels",
                    "unit": "200ml",
                    "company": "Natural Remedies",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Gel"
                },
                {
                    "id": 5,
                    "name": "Revitalizing Eye Cream",
                    "price": 850,
                    "image": "/images/beauty/product_5.png",
                    "description": "Reduces dark circles and puffiness.",
                    "category": "Skincare",
                    "subCategory": "Eye Care",
                    "unit": "15ml",
                    "company": "Youthful Eyes",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Cream"
                },
                {
                    "id": 6,
                    "name": "Moisturizing Body Lotion",
                    "price": 500,
                    "image": "/images/beauty/product_6.png",
                    "description": "Hydrates and softens all skin types.",
                    "category": "Body Care",
                    "subCategory": "Lotions",
                    "unit": "300ml",
                    "company": "Silky Skin",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Lotion"
                },
                {
                    "id": 7,
                    "name": "Exfoliating Scrub",
                    "price": 450,
                    "image": "/images/beauty/product_7.png",
                    "description": "Gently removes dead skin cells.",
                    "category": "Skincare",
                    "subCategory": "Scrubs",
                    "unit": "150ml",
                    "company": "Fresh Glow",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Scrub"
                },
                {
                    "id": 8,
                    "name": "Lip Care Balm",
                    "price": 250,
                    "image": "/images/beauty/product_8.png",
                    "description": "Hydrates and protects dry lips.",
                    "category": "Lip Care",
                    "subCategory": "Balms",
                    "unit": "15g",
                    "company": "Kissable Lips",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Balm"
                },
                {
                    "id": 9,
                    "name": "Organic Rose Water",
                    "price": 350,
                    "image": "/images/beauty/product_9.png",
                    "description": "Toner that refreshes and hydrates skin.",
                    "category": "Skincare",
                    "subCategory": "Toners",
                    "unit": "200ml",
                    "company": "Pure Essence",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Toner"
                },
                {
                    "id": 10,
                    "name": "Tea Tree Oil",
                    "price": 700,
                    "image": "/images/beauty/product_10.png",
                    "description": "Natural antiseptic for blemish control.",
                    "category": "Skincare",
                    "subCategory": "Oils",
                    "unit": "30ml",
                    "company": "Nature's Cure",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Oil"
                },
                {
                    "id": 11,
                    "name": "Anti-Aging Serum",
                    "price": 1200,
                    "image": "/images/beauty/product_11.png",
                    "description": "Reduces signs of aging and improves elasticity.",
                    "category": "Skincare",
                    "subCategory": "Serums",
                    "unit": "30ml",
                    "company": "Ageless Beauty",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Serum"
                },
                {
                    "id": 12,
                    "name": "Hydrating Face Mist",
                    "price": 400,
                    "image": "/images/beauty/product_12.png",
                    "description": "Instant hydration for dry skin.",
                    "category": "Skincare",
                    "subCategory": "Mists",
                    "unit": "100ml",
                    "company": "HydraFresh",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Mist"
                },
                {
                    "id": 13,
                    "name": "Facial Cleansing Oil",
                    "price": 750,
                    "image": "/images/beauty/product_13.png",
                    "description": "Removes makeup and impurities effectively.",
                    "category": "Skincare",
                    "subCategory": "Cleansers",
                    "unit": "200ml",
                    "company": "Clean Slate",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Cleanser"
                },
                {
                    "id": 14,
                    "name": "Clay Face Mask",
                    "price": 650,
                    "image": "/images/beauty/product_14.png",
                    "description": "Detoxifies and purifies the skin.",
                    "category": "Skincare",
                    "subCategory": "Masks",
                    "unit": "100ml",
                    "company": "Pure Clay",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Mask"
                },
                {
                    "id": 15,
                    "name": "Makeup Remover Wipes",
                    "price": 300,
                    "image": "/images/beauty/product_15.png",
                    "description": "Convenient wipes for easy makeup removal.",
                    "category": "Makeup",
                    "subCategory": "Removers",
                    "unit": "30 Wipes",
                    "company": "Quick Clean",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Wipes"
                },
                {
                    "id": 16,
                    "name": "Sunscreen SPF 50",
                    "price": 800,
                    "image": "/images/beauty/product_16.png",
                    "description": "Broad-spectrum protection against UV rays.",
                    "category": "Skincare",
                    "subCategory": "Sunscreens",
                    "unit": "50ml",
                    "company": "SunSafe",
                    "thumbnails": [
                        "path/to/thumb1.jpg",
                        "path/to/thumb2.jpg",
                        "path/to/thumb3.jpg"
                    ],
                    "type": "Sunscreen"
                }
            ]
            
          ,
      
      
            
            
                beauty: [
                    {
                        "id": 1,
                        "name": "Hydrating Facial Serum",
                        "price": 900,
                        "image": "/images/beauty/product_1.png",
                        "description": "Deeply hydrates and plumps skin.",
                        "category": "Skincare",
                        "subCategory": "Serums",
                        "unit": "30ml",
                        "company": "Radiant Skin",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Facial Care"
                    },
                    {
                        "id": 2,
                        "name": "Nourishing Night Cream",
                        "price": 950,
                        "image": "/images/beauty/product_2.png",
                        "description": "Repairs and rejuvenates skin overnight.",
                        "category": "Skincare",
                        "subCategory": "Creams",
                        "unit": "50ml",
                        "company": "Glow Beauty",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Night Care"
                    },
                    {
                        "id": 3,
                        "name": "Brightening Face Mask",
                        "price": 600,
                        "image": "/images/beauty/product_3.png",
                        "description": "Evens skin tone and brightens complexion.",
                        "category": "Skincare",
                        "subCategory": "Masks",
                        "unit": "100ml",
                        "company": "Bright Skin Co.",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Face Treatment"
                    },
                    {
                        "id": 4,
                        "name": "Soothing Aloe Vera Gel",
                        "price": 400,
                        "image": "/images/beauty/product_4.png",
                        "description": "Calms and hydrates irritated skin.",
                        "category": "Skincare",
                        "subCategory": "Gels",
                        "unit": "200ml",
                        "company": "Natural Remedies",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Soothing Care"
                    },
                    {
                        "id": 5,
                        "name": "Revitalizing Eye Cream",
                        "price": 850,
                        "image": "/images/beauty/product_5.png",
                        "description": "Reduces dark circles and puffiness.",
                        "category": "Skincare",
                        "subCategory": "Eye Care",
                        "unit": "15ml",
                        "company": "Youthful Eyes",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Eye Treatment"
                    },
                    {
                        "id": 6,
                        "name": "Moisturizing Body Lotion",
                        "price": 500,
                        "image": "/images/beauty/product_6.png",
                        "description": "Hydrates and softens all skin types.",
                        "category": "Body Care",
                        "subCategory": "Lotions",
                        "unit": "300ml",
                        "company": "Silky Skin",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Body Care"
                    },
                    {
                        "id": 7,
                        "name": "Exfoliating Scrub",
                        "price": 450,
                        "image": "/images/beauty/product_7.png",
                        "description": "Gently removes dead skin cells.",
                        "category": "Skincare",
                        "subCategory": "Scrubs",
                        "unit": "150ml",
                        "company": "Fresh Glow",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Exfoliating Care"
                    },
                    {
                        "id": 8,
                        "name": "Lip Care Balm",
                        "price": 250,
                        "image": "/images/beauty/product_8.png",
                        "description": "Hydrates and protects dry lips.",
                        "category": "Lip Care",
                        "subCategory": "Balms",
                        "unit": "15g",
                        "company": "Kissable Lips",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Lip Care"
                    },
                    {
                        "id": 9,
                        "name": "Organic Rose Water",
                        "price": 350,
                        "image": "/images/beauty/product_9.png",
                        "description": "Toner that refreshes and hydrates skin.",
                        "category": "Skincare",
                        "subCategory": "Toners",
                        "unit": "200ml",
                        "company": "Pure Essence",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Toner"
                    },
                    {
                        "id": 10,
                        "name": "Tea Tree Oil",
                        "price": 700,
                        "image": "/images/beauty/product_10.png",
                        "description": "Natural antiseptic for blemish control.",
                        "category": "Skincare",
                        "subCategory": "Oils",
                        "unit": "30ml",
                        "company": "Nature's Cure",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Oil Treatment"
                    },
                    {
                        "id": 11,
                        "name": "Anti-Aging Serum",
                        "price": 1200,
                        "image": "/images/beauty/product_11.png",
                        "description": "Reduces signs of aging and improves elasticity.",
                        "category": "Skincare",
                        "subCategory": "Serums",
                        "unit": "30ml",
                        "company": "Ageless Beauty",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Facial Care"
                    },
                    {
                        "id": 12,
                        "name": "Hydrating Face Mist",
                        "price": 400,
                        "image": "/images/beauty/product_12.png",
                        "description": "Instant hydration for dry skin.",
                        "category": "Skincare",
                        "subCategory": "Mists",
                        "unit": "100ml",
                        "company": "HydraFresh",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Hydrating Care"
                    },
                    {
                        "id": 13,
                        "name": "Facial Cleansing Oil",
                        "price": 750,
                        "image": "/images/beauty/product_13.png",
                        "description": "Removes makeup and impurities effectively.",
                        "category": "Skincare",
                        "subCategory": "Cleansers",
                        "unit": "200ml",
                        "company": "Clean Slate",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Cleansing Care"
                    },
                    {
                        "id": 14,
                        "name": "Clay Face Mask",
                        "price": 650,
                        "image": "/images/beauty/product_14.png",
                        "description": "Detoxifies and purifies the skin.",
                        "category": "Skincare",
                        "subCategory": "Masks",
                        "unit": "100ml",
                        "company": "Pure Clay",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Face Treatment"
                    },
                    {
                        "id": 15,
                        "name": "Makeup Remover Wipes",
                        "price": 300,
                        "image": "/images/beauty/product_15.png",
                        "description": "Convenient wipes for easy makeup removal.",
                        "category": "Makeup",
                        "subCategory": "Removers",
                        "unit": "30 Wipes",
                        "company": "Quick Clean",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Makeup Remover"
                    },
                    {
                        "id": 16,
                        "name": "Sunscreen SPF 50",
                        "price": 800,
                        "image": "/images/beauty/product_16.png",
                        "description": "Broad-spectrum protection against UV rays.",
                        "category": "Sunscreen",
                        "subCategory": "SPF",
                        "unit": "100ml",
                        "company": "Safe Sun",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Sun Protection"
                    },
                    {
                        "id": 17,
                        "name": "Hair Repair Oil",
                        "price": 600,
                        "image": "/images/beauty/product_17.png",
                        "description": "Strengthens and nourishes damaged hair.",
                        "category": "Hair Care",
                        "subCategory": "Oils",
                        "unit": "100ml",
                        "company": "Lush Locks",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Hair Treatment"
                    },
                    {
                        "id": 18,
                        "name": "Volumizing Shampoo",
                        "price": 700,
                        "image": "/images/beauty/product_18.png",
                        "description": "Gives body and volume to fine hair.",
                        "category": "Hair Care",
                        "subCategory": "Shampoos",
                        "unit": "250ml",
                        "company": "Bouncy Hair",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Shampoo"
                    },
                    {
                        "id": 19,
                        "name": "Nourishing Hair Mask",
                        "price": 750,
                        "image": "/images/beauty/product_19.png",
                        "description": "Deeply conditions and repairs hair.",
                        "category": "Hair Care",
                        "subCategory": "Masks",
                        "unit": "200ml",
                        "company": "Silky Strands",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Hair Treatment"
                    },
                    {
                        "id": 20,
                        "name": "Hair Styling Gel",
                        "price": 400,
                        "image": "/images/beauty/product_20.png",
                        "description": "Provides strong hold and shine.",
                        "category": "Hair Care",
                        "subCategory": "Gels",
                        "unit": "150ml",
                        "company": "Stylish Hair",
                        "thumbnails": [
                            "path/to/thumb1.jpg",
                            "path/to/thumb2.jpg",
                            "path/to/thumb3.jpg"
                        ],
                        "type": "Hair Styling"
                    }
                ]
                
              ,
              
              
 healthCorner: [
    {
        "id": 1,
        "name": "Multivitamin Tablets",
        "price": 1200,
        "image": "/images/healthcare/product_1.png",
        "description": "Comprehensive multivitamin for daily health.",
        "category": "Vitamins",
        "subCategory": "General Health",
        "unit": "60 tablets",
        "company": "HealthPlus",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Vitamin"
    },
    {
        "id": 2,
        "name": "Omega-3 Fish Oil",
        "price": 900,
        "image": "/images/healthcare/product_2.png",
        "description": "Supports heart and brain health.",
        "category": "Supplements",
        "subCategory": "Fatty Acids",
        "unit": "30 softgels",
        "company": "Nature's Way",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    },
    {
        "id": 3,
        "name": "Probiotic Capsules",
        "price": 800,
        "image": "/images/healthcare/product_3.png",
        "description": "Promotes digestive health and gut flora.",
        "category": "Digestive Health",
        "subCategory": "Probiotics",
        "unit": "30 capsules",
        "company": "GutHealth",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    },
    {
        "id": 4,
        "name": "Vitamin D3 Drops",
        "price": 700,
        "image": "/images/healthcare/product_4.png",
        "description": "Supports bone and immune health.",
        "category": "Vitamins",
        "subCategory": "Vitamin D",
        "unit": "30ml",
        "company": "Sunny Days",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Vitamin"
    },
    {
        "id": 5,
        "name": "Iron Supplements",
        "price": 600,
        "image": "/images/healthcare/product_5.png",
        "description": "Essential for red blood cell production.",
        "category": "Minerals",
        "subCategory": "Iron",
        "unit": "60 tablets",
        "company": "IronBoost",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Mineral"
    },
    {
        "id": 6,
        "name": "Herbal Cough Syrup",
        "price": 500,
        "image": "/images/healthcare/product_6.png",
        "description": "Natural relief for cough and throat irritation.",
        "category": "Cold & Flu",
        "subCategory": "Cough Relief",
        "unit": "100ml",
        "company": "Herbal Remedies",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Medicine"
    },
    {
        "id": 7,
        "name": "Electrolyte Powder",
        "price": 400,
        "image": "/images/healthcare/product_7.png",
        "description": "Replenishes electrolytes lost during exercise.",
        "category": "Hydration",
        "subCategory": "Electrolytes",
        "unit": "10 sachets",
        "company": "Active Life",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    },
    {
        "id": 8,
        "name": "Pain Relief Cream",
        "price": 300,
        "image": "/images/healthcare/product_8.png",
        "description": "Topical cream for muscle and joint pain.",
        "category": "Pain Management",
        "subCategory": "Topicals",
        "unit": "50g",
        "company": "Relief Co.",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Medicine"
    },
    {
        "id": 9,
        "name": "Sleep Aid Tablets",
        "price": 700,
        "image": "/images/healthcare/product_9.png",
        "description": "Helps promote restful sleep.",
        "category": "Sleep Support",
        "subCategory": "Sleep Aids",
        "unit": "30 tablets",
        "company": "Sleep Well",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    },
    {
        "id": 10,
        "name": "Allergy Relief Tablets",
        "price": 550,
        "image": "/images/healthcare/product_10.png",
        "description": "Effective relief for seasonal allergies.",
        "category": "Allergy Relief",
        "subCategory": "Antihistamines",
        "unit": "30 tablets",
        "company": "ClearAir",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Medicine"
    },
    {
        "id": 11,
        "name": "Joint Health Formula",
        "price": 1300,
        "image": "/images/healthcare/product_11.png",
        "description": "Supports joint flexibility and comfort.",
        "category": "Joint Care",
        "subCategory": "Supplements",
        "unit": "60 capsules",
        "company": "JointCare",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    },
    {
        "id": 12,
        "name": "Digestive Enzymes",
        "price": 850,
        "image": "/images/healthcare/product_12.png",
        "description": "Aids in digestion and nutrient absorption.",
        "category": "Digestive Health",
        "subCategory": "Enzymes",
        "unit": "60 capsules",
        "company": "DigestEase",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    },
    {
        "id": 13,
        "name": "Calcium & Vitamin D",
        "price": 700,
        "image": "/images/healthcare/product_13.png",
        "description": "Supports bone health and strength.",
        "category": "Bone Health",
        "subCategory": "Calcium",
        "unit": "60 tablets",
        "company": "BoneStrong",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    },
    {
        "id": 14,
        "name": "Glucosamine Supplement",
        "price": 950,
        "image": "/images/healthcare/product_14.png",
        "description": "Promotes joint health and mobility.",
        "category": "Joint Care",
        "subCategory": "Supplements",
        "unit": "60 capsules",
        "company": "FlexJoint",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    },
    {
        "id": 15,
        "name": "Antioxidant Complex",
        "price": 850,
        "image": "/images/healthcare/product_15.png",
        "description": "Protects against free radical damage.",
        "category": "Antioxidants",
        "subCategory": "Supplements",
        "unit": "60 capsules",
        "company": "FreeRadical Defense",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    },
    {
        "id": 16,
        "name": "Cinnamon Extract",
        "price": 600,
        "image": "/images/healthcare/product_16.png",
        "description": "Supports healthy blood sugar levels.",
        "category": "Herbal Supplements",
        "subCategory": "Extracts",
        "unit": "60 capsules",
        "company": "CinnaHealth",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Herbal"
    },
    {
        "id": 17,
        "name": "Turmeric Curcumin",
        "price": 950,
        "image": "/images/healthcare/product_17.png",
        "description": "Powerful anti-inflammatory properties.",
        "category": "Herbal Supplements",
        "subCategory": "Extracts",
        "unit": "60 capsules",
        "company": "Golden Spice",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Herbal"
    },
    {
        "id": 18,
        "name": "Elderberry Gummies",
        "price": 750,
        "image": "/images/healthcare/product_18.png",
        "description": "Supports immune health with antioxidants.",
        "category": "Herbal Supplements",
        "subCategory": "Gummies",
        "unit": "60 gummies",
        "company": "BerryBoost",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Herbal"
    },
    {
        "id": 19,
        "name": "Stress Relief Tea",
        "price": 450,
        "image": "/images/healthcare/product_19.png",
        "description": "Herbal blend to help relieve stress.",
        "category": "Herbal Remedies",
        "subCategory": "Teas",
        "unit": "20 bags",
        "company": "CalmTea",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Herbal"
    },
    {
        "id": 20,
        "name": "Men's Health Formula",
        "price": 950,
        "image": "/images/healthcare/product_20.png",
        "description": "Supports men's health and vitality.",
        "category": "Men's Health",
        "subCategory": "Supplements",
        "unit": "60 tablets",
        "company": "VitalMen",
        "thumbnails": [
            "path/to/thumb1.jpg",
            "path/to/thumb2.jpg",
            "path/to/thumb3.jpg"
        ],
        "type": "Supplement"
    }
  ]
  

};


export const users = [
    { username: "admin", password: "admin", role: "admin" },
    { username: "user1", password: "user1", role: "user" },
];

export const products = {
    medicines: [
        { id: 1, name: "Medicine A", price: 10 },
        { id: 2, name: "Medicine B", price: 15 },
    ],
    general: [
        { id: 3, name: "General Product A", price: 20 },
        { id: 4, name: "General Product B", price: 25 },
    ],
};
