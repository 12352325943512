
import React, { useState, useEffect } from 'react';
import PrescriptionUpload from '../pages/PrescriptionUpload'
import { FaCheckCircle, FaShoppingCart, FaLock, FaTruck } from 'react-icons/fa'; // Import icons


import '../styles/Home.css';

const Home = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const slides = [
        { src: 'images/Home/home_slide_1.jpg', alt: 'Image 1' },
        { src: 'images/Home/home_slide_2.jpg', alt: 'Image 2' },
        { src: 'images/Home/home_slide_3.jpg', alt: 'Image 3' },
        { src: 'images/Home/home_slide_4.jpg', alt: 'Image 4' },
    ];


    const categories = [
        { img: '/images/Home/Vitamins.png', name: 'Vitamins', offer: 'Upto 20% Off' },
        { img: '/images/Home/Skin Care.png', name: 'Skin Care', offer: 'Upto 15% Off' },
     
        { img: '/images/Home/Wellness.png', name: 'Wellness', offer: 'Upto 30% Off' },
        { img: '/images/Home/Baby Care.png', name: 'Baby Care', offer: 'Upto 15% Off' },
        { img: '/images/Home/Herbal Remedies.png', name: 'Herbal Remedies', offer: 'Upto 20% Off' },
        { img: '/images/Home/Fitness.png', name: 'Fitness', offer: 'Upto 15% Off' },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval);
    }, [slides.length]);

    const changeSlide = (n) => {
        setSlideIndex((prevIndex) => (prevIndex + n + slides.length) % slides.length);
    };


  
    return (
        <div>
           
            <div className="slideshow-container">
                {slides.map((slide, index) => (
                    <div className={`slide ${index === slideIndex ? 'fade' : ''}`} key={index}>
                        <img src={slide.src} alt={slide.alt} />
                    </div>
                ))}
                <a className="prev" onClick={() => changeSlide(-1)}>&#10094;</a>
                <a className="next" onClick={() => changeSlide(1)}>&#10095;</a>
            </div>


               <div className="category-section">
                    <h2 className="category-heading">Shop by Category</h2>
                        <div className="card-container">
                            {categories.map((category) => (
                                <div className="card_cat" key={category.name}>
                                    <img src={category.img} alt={category.name} className="card-image" />
                                        <div className="card-content">
                                            <h3 className="card-title">{category.name}</h3>
                                            <p className="card-offer">{category.offer}</p>
                                        </div>
                                </div>
                                  ))}
                         </div>
             </div>


             
         <PrescriptionUpload />


         <div className="homepage-container">
      {/* Left Side: Content Area */}
      <div className="content-area">
        <h1>Remarkable Features that You Can Count!</h1>
        <p>
          Jam-packed with outstanding features to elevate your online ordering 
          and delivery easier, and smarter than ever before. It's time to empower 
          your multivendor online business with 6amMart's powerful features!
        </p>
      </div>

      {/* Right Side: Feature Cards */}
      <div className="features-container">
        <div className="features-area">
          <div className="feature-card left">
            <FaCheckCircle className="icon" />
            <h3>Trusted</h3>
            <p>Trusted by customers and store owners.</p>
          </div>
          <div className="feature-card right">
            <FaShoppingCart className="icon" />
            <h3>Shopping</h3>
            <p>Best shopping experience.</p>
          </div>
          <div className="feature-card left">
            <FaLock className="icon" />
            <h3>Payment</h3>
            <p>Total secured payment system.</p>
          </div>
          <div className="feature-card right">
            <FaTruck className="icon" />
            <h3>Delivery</h3>
            <p>Flexible delivery system.</p>
          </div>

          <div className="feature-card left">
            <FaCheckCircle className="icon" />
            <h3>Trusted</h3>
            <p>Trusted by customers and store owners.</p>
          </div>
          <div className="feature-card right">
            <FaShoppingCart className="icon" />
            <h3>Shopping</h3>
            <p>Best shopping experience.</p>
          </div>
          <div className="feature-card left">
            <FaLock className="icon" />
            <h3>Payment</h3>
            <p>Total secured payment system.</p>
          </div>
          <div className="feature-card right">
            <FaTruck className="icon" />
            <h3>Delivery</h3>
            <p>Flexible delivery system.</p>
          </div>
          {/* Add more feature cards as needed */}
        </div>
      </div>
    </div>



        </div>
    );
};

export default Home;
